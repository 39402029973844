<template>
    <v-container>
        <PerfilUsuarioComponente></PerfilUsuarioComponente>
    </v-container>
</template>

<script>
    import PerfilUsuarioComponente from '@/components/usuarios/componentes/PerfilUsuarioComponente.vue';
    export default {
        components: {
            PerfilUsuarioComponente,
        },
        
    }
</script>

<style scoped>

</style>